@import "~antd/dist/antd.less";
@font-face {
  font-family: "VarelaRound";
  src: url(./fonts/VarelaRound-Regular.otf) format("opentype");
}

body,
#root {
  height: 100%;
}

.logo {
  height: 80px;
  margin: 16px;
}

.logoRound {
  height: 100px;
  margin: 150px;
}

.logoRect {
  width: 800px;
  height: 200px;
  //border-radius: 50%;
}

.ant-drawer-body {
  padding: 0;
}

.ant-layout-content {
  min-height: auto;
  overflow: auto;
}

.ant-page-header-heading-left {
  align-items: baseline;
}

.ant-layout {
  width: auto;
  text-align: left;
}

@primary-color: #664099;

@font-family: "VarelaRound";

.avatar-uploader > .ant-upload {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.avatar-uploader-round > .ant-upload {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.avatar-uploader-rect > .ant-upload {
  border-radius: 25px;
  width: 600px;
  height: 150px;
}

.coupon-logo > .ant-upload {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.ant-dropdown-trigger > span > svg {
  fill: #616161;
}

.warningPattern {
  background-color: #664099;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 7px, #d1f125 7px, #d1f125 14px);
  margin-top: 20px;
  margin-right: 20px;
  height: 20px;
  width: auto;
}

.ant-btn-primary {
  color: #664099;
  background: #d1f125;
  border-color: #d1f125;
}

.ant-btn-primary {
  color: #664099;
  background: #e2f797;
  border-color: #d1f125;
}

.ant-btn {
  color: #664099;
  background: #d1f125;
  border-color: #d1f125;
}

.ant-btn:hover {
  color: #664099;
  background: #e2f797;
  border-color: #d1f125;
}
.ant-text-grey {
  color: #8f8f8f69;
}
.ant-col {
  position: inherit;
  max-width: 100%;
}

.ant-layout-sider {
  background: #664099;
}

.ant-layout-header {
  background: #664099;
  margin-left: initial;
}
.ant-col warningPattern {
}
.ant-tooltip-inner {
  background-color: rgba(102, 64, 153, 0.75);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #d1f125;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #fff;
}

.ant-drawer-content-wrapper {
  position: fixed;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #664099;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #664099;
}

.ant-layout-sider-trigger {
  background: #7d69a9;
  position: fixed;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #7d69a9;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #fff;
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #664099;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #595959;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  border-color: #595959;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #664099;
}

@prim: #664099;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-weight: 300;
}

.wrapper {
  background: #7d69a9;
  background: -webkit-linear-gradient(top left, #7d69a9 0%, #664099 100%);
  background: -moz-linear-gradient(top left, #7d69a9 0%, #664099 100%);
  background: -o-linear-gradient(top left, #7d69a9 0%, #664099 100%);
  background: linear-gradient(to bottom right, #7d69a9 0%, #664099 100%);

  left: 0;
  width: 100%;
  height: 8%;
  overflow: hidden;
}

.bg-bubbles {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1;

  li {
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: fade(#d1f125, 15%);
    bottom: -160px;

    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;

    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;

    &:nth-child(1) {
      margin-left: 10%;
    }

    &:nth-child(2) {
      margin-left: 20%;

      width: 80px;
      height: 80px;

      animation-delay: 2s;
      animation-duration: 17s;
    }

    &:nth-child(3) {
      margin-left: 25%;
      animation-delay: 4s;
    }

    &:nth-child(4) {
      margin-left: 40%;
      width: 60px;
      height: 60px;

      animation-duration: 22s;

      background-color: fade(#d1f125, 25%);
    }

    &:nth-child(5) {
      left: 70%;
    }

    &:nth-child(6) {
      margin-left: 80%;
      width: 120px;
      height: 120px;

      animation-delay: 3s;
      background-color: fade(#d1f125, 20%);
    }

    &:nth-child(7) {
      margin-left: 32%;
      width: 160px;
      height: 160px;

      animation-delay: 7s;
    }

    &:nth-child(8) {
      margin-left: 55%;
      width: 20px;
      height: 20px;

      animation-delay: 15s;
      animation-duration: 40s;
    }

    &:nth-child(9) {
      margin-left: 25%;
      width: 10px;
      height: 10px;

      animation-delay: 2s;
      animation-duration: 40s;
      background-color: fade(#d1f125, 30%);
    }

    &:nth-child(10) {
      margin-left: 90%;
      width: 160px;
      height: 160px;

      animation-delay: 11s;
    }
  }
}

@-webkit-keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-700px) rotate(600deg);
  }
}
@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-700px) rotate(600deg);
  }
}

@layout-body-background: #fff;